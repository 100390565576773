import { client } from '@/ReactQueryProvider';
import { useMutation, useQuery } from '@tanstack/react-query';
import {createComment, deleteComment, getBirthdays, getComments, getWeather, updateComment} from '../api';
import {
  CreateScheduleCommentPayload,
  UpdateScheduleCommentPayload,
  V4BirthdayResponse,
  V4CommentResponse,
  V4WeatherResponse,
} from '../types';

export const useScheduleComments = (
  startDate: string,
  endDate: string,
): {
  comments?: V4CommentResponse[];
  loading: boolean;
} => {
  const { data: comments, isLoading: loading } = useQuery({
    queryKey: ['get-schedule-comments', startDate, endDate],
    queryFn: () => getComments(startDate, endDate),
  });

  return {
    loading,
    comments,
  };
};

export const useCreateScheduleComment = (onSuccess: () => void) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['create-schedule-comment'],
    mutationFn: (payload: CreateScheduleCommentPayload) => createComment(payload),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['get-schedule-comments'] });
      onSuccess();
    },
  });

  return {
    createScheduleComment: mutate,
    isLoading,
  };
};

export const useUpdateScheduleComment = (commentId: string, onSuccess: () => void) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['update-schedule-comment'],
    mutationFn: (payload: UpdateScheduleCommentPayload) => updateComment(commentId, payload),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['get-schedule-comments'] });
      onSuccess();
    },
  });

  return {
    updateScheduleComment: mutate,
    isLoading,
  };
};

export const useDeleteScheduleComment = (onSuccess: () => void) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['delete-schedule-comment'],
    mutationFn: (commentId: string) => deleteComment(commentId),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['get-schedule-comments'] });
      onSuccess();
    },
  });

  return {
    deleteScheduleComment: mutate,
    isLoading,
  };
};

export const useWeather = (): {
  weather?: V4WeatherResponse[];
  loading: boolean;
} => {
  const { data: weather, isLoading: loading } = useQuery({
    queryKey: ['get-weather'],
    queryFn: () => getWeather(),
  });

  return {
    loading,
    weather,
  };
};

export const useBirthdays = (
  startDate: string,
  endDate: string,
): {
  birthdays?: V4BirthdayResponse[];
  loading: boolean;
} => {
  const { data: birthdays, isLoading: loading } = useQuery({
    queryKey: ['get-birthdays', startDate, endDate],
    queryFn: () => getBirthdays(startDate, endDate),
  });

  return {
    loading,
    birthdays,
  };
};
