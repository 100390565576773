import { usePusher } from '@/components/PusherProvider';
import AuthContext from '@/context';
import { useWindowSize } from '@/hooks/use-window-size';
import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import {
  DIMONA_WORKER_TYPES,
  REGIME_TYPES,
  TINYMCE_CONTENT_STYLE,
  TINYMCE_PLUGINS,
  TINYMCE_TOOLBAR,
  appendToFilename,
  getHtmlWithCodes,
  getHtmlWithVariables,
  handleError,
  isFeatureEnabled,
  isset,
  variablesObjectToArray,
} from '@/utils';
import { Editor } from '@tinymce/tinymce-react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
  Upload,
  message,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { RcFile } from 'antd/lib/upload';
import { default as axios } from 'axios';
import moment from 'moment';
import 'moment-timezone';
import { Channel } from 'pusher-js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { uploadFileToSignedUrl } from '../../api';
import ModalVariablesUsage from '../../components/modal-variables-usage';
import { useSignatures } from '../../signatures/hooks/use-signatures';
import { V4DocumentVariables } from '../../types/index.types';
import { createContract, editContract } from '../api';
import { useContract } from '../hooks/use-contract';
import { useContractVariables } from '../hooks/use-contract-variables';
import { useContractTemplates } from '../templates/hooks/use-contract-templates';
import { V4ManageContractPayload } from '../types/contracts.types';
import Header from './header';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
}

const Contract: React.FC<Props> = ({ className }) => {
  const [placesOfWork, setPlacesOfWork] = useState<string[]>([]);

  // V4
  const {
    state: { users, loadingUsers, activeDepartmentId, features },
  } = useContext(AppContext);
  const {
    state: { userDetails },
  } = useContext(AuthContext);

  const { t } = useTranslation();
  const [form] = useForm();
  const location = useLocation<any>();
  const history: any = useHistory();
  const { contractId } = useParams<any>();
  const placeholdersRef = useRef<V4DocumentVariables | null>(null);
  const editorRef = useRef<any>(null);
  const pusher = usePusher();
  const {
    userRecordId: shiftRightClickUserRecordId,
    weekHours: shiftRightClickWeekHours,
    comingFrom: shiftRightClickComingFrom,
    start: shiftRightClickStart,
    end: shiftRightClickEnd,
    salaryPeriod: shiftRightClickSalaryPeriod,
    weekDays: shiftRightClickWeekDays,
    maxDailyHours: shiftRightClickMaxDailyHours,
    salaryAmountGross: shiftRightClickSalaryAmountGross,
  } = history.location.state || {};

  const { windowSize } = useWindowSize();
  const { contract: activeContract, loading: loadingContract } = useContract(contractId);
  const { contractTemplates, loading: loadingContractTemplates } = useContractTemplates();
  const { signatures, loading: loadingSignatures } = useSignatures();
  const { contractVariables } = useContractVariables();

  const [loading, setLoading] = useState<boolean>(false);
  const [modalVariablesUsageVisible, setModalVariablesUsageVisible] = useState(false);
  const [hideDimonaExtra, setHideDimonaExtra] = useState(true);
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [activeTemplateId, setActiveTemplateId] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const [uploadedFileData, setUploadedFileData] = useState<{
    documentFiles: string[];
  } | null>(null);
  const [previewPdf, setPreviewPdf] = useState<string | null>(null);

  const [activeUserRecordId, setActiveUserRecordId] = useState<string | null>(null);
  const [activeUserHrData, setActiveUserHrData] = useState<any>(null);
  const [loadingActiveUserHrData, setLoadingActiveUserHrData] = useState<boolean>(false);

  const [activeUserParams, setActiveUserParams] = useState<any>(null);
  const [loadingActiveUserParams, setLoadingActiveUserParams] = useState<boolean>(false);

  const newContract = contractId == 'new';
  const { state } = location as any;
  const activeTemplate = contractTemplates.find((contractTemplate) => contractTemplate.id == activeTemplateId);
  const locked = activeContract ? !!activeContract.sentAt : false;

  useEffect(() => {
    let channel: Channel | null = null;

    if (pusher && activeDepartmentId && userDetails) {
      channel = pusher.channel(`private-App.User.${userDetails.id}.Department.${activeDepartmentId}`);

      if (!channel) return;

      const callbacks = channel.callbacks;

      if (!callbacks.get('document_file_preview_generated')) {
        channel.bind('document_file_preview_generated', async (message: any) => {
          setPreviewPdf(message.pdfUrl);
        });
      }
    }

    return () => {
      if (channel) {
        channel.unbind();
      }
    };
  }, [pusher, activeDepartmentId, userDetails]);

  useEffect(() => {
    if (!file) return;
    const upload = async () => {
      const data = await uploadFileToSignedUrl(
        [
          {
            filename: appendToFilename(file.name, `${activeDepartmentId}_${moment().unix()}`),
            filetype: file.type,
          },
        ],
        [file],
        true,
      );
      setUploadedFileData(data);
    };
    upload();
  }, [file]);

  // Add variables to the editor "variabled" selector
  useEffect(() => {
    if (contractVariables) {
      placeholdersRef.current = contractVariables;
    }
  }, [contractVariables]);

  useEffect(() => {
    setActiveUserRecordId(shiftRightClickUserRecordId);
    form.setFieldsValue({
      userRecordId: shiftRightClickUserRecordId,
    });
  }, [shiftRightClickUserRecordId]);

  useEffect(() => {
    if (!state || !state.userRecordId) return;
    setActiveUserRecordId(state.userRecordId);
  }, [window.location.pathname, state]);

  useEffect(() => {
    setPreviewPdf(null);

    if (!activeContract) return;

    setActiveUserRecordId(activeContract.userRecordId);

    if (activeContract.type == 'upload' && activeContract.fileName) {
      setFileName(activeContract.fileName);
    }

    form.setFieldsValue({
      ...activeContract,
      start: moment(activeContract.start),
      end: activeContract.end ? moment(activeContract.end) : undefined,
      careerStartDate: activeContract.careerStartDate ? moment(activeContract.careerStartDate) : undefined,
      hireDate: activeContract.hireDate ? moment(activeContract.hireDate) : undefined,
      seniority: activeContract.seniority ? moment(activeContract.seniority) : undefined,
      dimona: !!activeContract.dimonaWorkerType,
      workerType: activeContract.dimonaWorkerType,
      dimonaPlannedHours: activeContract.dimonaPlannedHours,
    });

    if (activeContract.type == 'html') {
      setHtmlContent(getHtmlWithCodes(activeContract.html, contractVariables));
    }

    if (activeContract.dimonaWorkerType == 'STU') {
      setHideDimonaExtra(false);
    }

    if (activeContract.pdfUrl) {
      setPreviewPdf(activeContract.pdfUrl);
    }
  }, [location.pathname, activeContract, activeContract, contractVariables]);

  // Set default signatures (if any)
  useEffect(() => {
    const defaultSignature = signatures.find((signature) => signature.default);

    if (!defaultSignature) return;

    form.setFieldsValue({
      signatureId: defaultSignature.id,
    });
  }, [signatures]);

  // Get user's default data
  useEffect(() => {
    if (!activeUserRecordId) return;

    getActiveUserHrData();
    getActiveUserParams();
  }, [activeUserRecordId]);

  // Set fields with user active hr data
  useEffect(() => {
    if (!activeUserHrData || activeContract) return;

    const {
      price: udPrice,
      price_bruto: udPriceBruto,
      wage_type: udWageType,
      hr_max_hours_week: udHrMaxHoursWeek,
      hr_days_week: udDaysWeek,
      legal_leaves: udLegalLeaves,
      default_dimona_hours: udDefaultDimonaHours,
      regime: udWorkingTimePlan,
    } = activeUserHrData;

    let udMaxHoursDay = 0;
    if (isset(udDaysWeek) && isset(udHrMaxHoursWeek)) {
      udMaxHoursDay = udHrMaxHoursWeek / udDaysWeek;
    }

    let wage = null;
    if (isset(udWageType)) {
      if (udWageType == '1') wage = 'hours';
      if (udWageType == '2') wage = 'days';
      if (udWageType == '3') wage = 'weeks';
      if (udWageType == '4') wage = 'months';
    }

    const { start, end } = form.getFieldsValue();

    const newValues = {
      start: isset(shiftRightClickStart) ? moment.unix(shiftRightClickStart) : isset(start) ? start : null,
      end: isset(shiftRightClickEnd) ? moment.unix(shiftRightClickEnd) : isset(end) ? end : null,
      salaryAmountGross: isset(shiftRightClickSalaryAmountGross)
        ? shiftRightClickSalaryAmountGross
        : udPriceBruto
        ? udPriceBruto
        : undefined,
      salaryPeriod: isset(shiftRightClickSalaryPeriod) ? shiftRightClickSalaryPeriod : isset(wage) ? wage : undefined,
      hoursWeek: isset(shiftRightClickWeekHours)
        ? shiftRightClickWeekHours
        : udHrMaxHoursWeek
        ? udHrMaxHoursWeek
        : undefined,
      daysWeek: isset(shiftRightClickWeekDays) ? shiftRightClickWeekDays : udDaysWeek ? udDaysWeek : undefined,
      daysOffYear: udLegalLeaves ? udLegalLeaves : undefined,
      maxHoursDay: isset(shiftRightClickMaxDailyHours)
        ? shiftRightClickMaxDailyHours
        : udMaxHoursDay
        ? Math.floor(udMaxHoursDay)
        : undefined,
      workingTimePlan: udWorkingTimePlan ? udWorkingTimePlan : undefined,
      dimonaPlannedHours: isset(shiftRightClickWeekHours)
        ? shiftRightClickWeekHours
        : udDefaultDimonaHours
        ? udDefaultDimonaHours
        : undefined,
    };

    form.setFieldsValue(newValues);
  }, [activeUserHrData]);

  // Set fields with user params
  useEffect(() => {
    if (!activeUserParams || activeContract) return;

    const {
      automatic_contract_template_id: upAutomaticContractTemplateId,
      automatic_contracts: upAutomaticContracts,
      workerType: upWorkerType,
      hr_hours_week: upHrHoursWeek,
    } = activeUserParams;

    const newValues = {
      dimona: !!upWorkerType,
      templateId: upAutomaticContractTemplateId ? upAutomaticContractTemplateId : undefined,
      autorenew: upAutomaticContracts ? upAutomaticContracts : undefined,
      workerType: upWorkerType ? upWorkerType : undefined,
      hoursWeek: upHrHoursWeek ? upHrHoursWeek : undefined,
    };

    if (upAutomaticContractTemplateId) {
      onWantToChangeTemplateId(upAutomaticContractTemplateId);
    }

    if (upWorkerType) {
      setHideDimonaExtra(upWorkerType !== 'STU');
    }

    form.setFieldsValue(newValues);
  }, [activeUserParams]);

  // Set template data
  useEffect(() => {
    if (!activeTemplate || activeContract) return;

    const {
      maxHoursDay,
      salaryAmountGross,
      salaryPeriod,
      signatureId,
      daysWeek,
      hoursWeek,
      daysOffYear,
    } = form.getFieldsValue();

    const {
      fields: {
        maxHoursDay: tdMaxHoursDay,
        salaryPeriod: tdSalaryPeriod,
        signatureId: tdSignatureId,
        daysWeek: tdDaysWeek,
        hoursWeek: tdHoursWeek,
        daysOffYear: tdDaysOffYear,
        salaryAmountGross: tdsalaryAmountGross,
      },
      html,
      fileName,
      type,
      file,
    } = activeTemplate;

    if (fileName && type == 'upload') {
      setFileName(fileName);
    }

    if (html) {
      setHtmlContent(getHtmlWithCodes(html, contractVariables));
    }

    if (file) {
      setPreviewPdf(file);
    }

    form.setFieldsValue({
      salaryAmountGross: isset(tdsalaryAmountGross)
        ? tdsalaryAmountGross
        : salaryAmountGross
        ? salaryAmountGross
        : undefined,
      salaryPeriod: isset(tdSalaryPeriod) ? `${tdSalaryPeriod}` : isset(salaryPeriod) ? salaryPeriod : undefined,
      daysWeek: isset(tdDaysWeek) ? tdDaysWeek : isset(daysWeek) ? daysWeek : undefined,
      hoursWeek: isset(tdHoursWeek) ? tdHoursWeek : isset(hoursWeek) ? hoursWeek : undefined,
      daysOffYear: isset(tdDaysOffYear) ? tdDaysOffYear : isset(daysOffYear) ? daysOffYear : undefined,
      maxHoursDay: isset(tdMaxHoursDay) ? tdMaxHoursDay : isset(maxHoursDay) ? maxHoursDay : undefined,
      signatureId: isset(tdSignatureId) ? tdSignatureId : isset(signatureId) ? signatureId : undefined,
    });
  }, [form, activeTemplate, contractTemplates, contractVariables]);

  // Places of work
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/department/places-of-work`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        setPlacesOfWork(data);
      })
      .catch((err) => {
        console.log(err);
        message.error(t('DIMONA.GET_PLACES_OF_WORK_ERROR'));
      });
  }, [contractId]);

  const getActiveUserHrData = () => {
    setLoadingActiveUserHrData(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${activeUserRecordId}/hr/active`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        setActiveUserHrData(data.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoadingActiveUserHrData(false);
      });
  };

  const getActiveUserParams = () => {
    setLoadingActiveUserParams(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${activeUserRecordId}/params`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        setActiveUserParams({ ...data.params });
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoadingActiveUserParams(false);
      });
  };

  const onWantToChangeTemplateId = (value: string) => {
    if (editorRef?.current?.getContent()) {
      Modal.confirm({
        title: t('GLOBAL.WARNING'),
        type: 'warning',
        content: t('DOCUMENTS.MODAL_CHANGE_TEMPLATE.CONTENT'),
        cancelText: t('GLOBAL.CANCEL'),
        okText: t('GLOBAL.VALIDATE'),
        okType: 'danger',
        okButtonProps: { type: 'primary' },
        cancelButtonProps: { type: 'primary' },
        onOk: () => {
          setFile(null);
          setFileName(null);
          setActiveTemplateId(value);
        },
        onCancel: () => {
          form.setFieldsValue({ templateId: activeTemplateId });
        },
      });
    } else {
      setFile(null);
      setFileName(null);
      setActiveTemplateId(value);
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);

    const basePayload = {
      userRecordId: values.userRecordId || undefined,
      signatureId: values.signatureId,
      templateId: values.templateId || undefined,
      start: values.start.format('YYYY-MM-DD'),
      startHour: shiftRightClickComingFrom == 'SHIFT_RIGHT_CLICK' ? values.start.format('HH:mm') : undefined,
      end: values.end?.format('YYYY-MM-DD'),
      endHour: shiftRightClickComingFrom == 'SHIFT_RIGHT_CLICK' ? values.end.format('HH:mm') : undefined,
      hoursWeek: values.hoursWeek,
      daysWeek: values.daysWeek,
      maxHoursDay: values.maxHoursDay,
      daysOffYear: values.daysOffYear,
      salaryAmountNet: 1,
      salaryAmountGross: values.salaryAmountGross,
      salaryPeriod: values.salaryPeriod,
      renewal: values.autorenew ? { period: values.renewPeriod, option: values.renewOption } : null,
      dimonaWorkerType: values.workerType,
      dimonaPlannedHours: values.dimonaPlannedHours ? Number(values.dimonaPlannedHours) : undefined,
      placeOfWork: values.placeOfWork,
      hireDate: values.hireDate?.format('YYYY-MM-DD'),
      seniority: values.seniority?.format('YYYY-MM-DD'),
      careerStartDate: values.careerStartDate?.format('YYYY-MM-DD'),
      standardHoursPerWeek: values.standardHoursPerWeek,
      schedule: values.schedule,
      workingTimePlan: values.workingTimePlan,
    };

    let payload: V4ManageContractPayload;

    payload = {
      ...basePayload,
      type: 'html',
      html: getHtmlWithVariables(editorRef?.current?.getContent()),
    };

    if (file && uploadedFileData) {
      payload = {
        ...basePayload,
        type: 'upload',
        file: uploadedFileData.documentFiles[0],
      };
    } else if (fileName && activeContract?.documentFileId) {
      payload = {
        ...basePayload,
        type: 'upload',
        file: activeContract?.documentFileId,
      };
    } else if (fileName && activeTemplate?.documentFileId) {
      payload = {
        ...basePayload,
        type: 'upload',
        file: activeTemplate?.documentFileId,
      };
    }

    try {
      if (newContract) {
        await createContract(payload);
        message.success(t('documents.contracts.contract-created'));
      } else {
        await editContract(contractId, payload);
        message.success(t('documents.contracts.contract-edited'));
      }
      history.push('/app/documents/contracts/all');
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const beforeUpload = (file: RcFile) => {
    setFile(file);
    setPreviewPdf(null);
    return false;
  };

  const onClearTemplate = () => {
    setFile(null);
    setFileName(null);
  };

  const onVariablesUsage = () => {
    setModalVariablesUsageVisible(true);
  };

  const onUserChange = (userRecordId: string | null) => {
    setActiveUserRecordId(userRecordId);
    setActiveTemplateId(null);
  };

  const onValuesChange = (changedValues: any, values: any) => {
    if (changedValues.renewPeriod) {
      form.setFieldsValue({ renewOption: undefined });
    }
    if (values.workerType && values.workerType == 'STU') {
      setHideDimonaExtra(false);
    }
  };

  return (
    <>
      <ModalVariablesUsage
        visible={modalVariablesUsageVisible}
        setVisible={setModalVariablesUsageVisible}
        variables={contractVariables}
      />
      <TableView className={className}>
        <Header locked={locked} form={form} loading={loading} />
        <Spin spinning={loading || loadingContract || loadingActiveUserHrData || loadingActiveUserParams}>
          <Form
            layout="vertical"
            size="large"
            form={form}
            onFinish={onFinish}
            scrollToFirstError={true}
            className="contract-container"
            onValuesChange={onValuesChange}
          >
            <Row gutter={[20, 30]}>
              <Col span={windowSize.innerWidth > 900 ? 12 : 24}>
                <div className="card-shadow">
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                      name="userRecordId"
                      label={t('CONTRACTS.FORM.WHO')}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(50% - 5px)' : '100%',
                        marginRight: '10px',
                      }}
                      rules={[{ required: true }]}
                    >
                      <Select
                        defaultValue={state && state.userRecordId}
                        showSearch
                        placeholder={t('FORMS.USER_PLACEHOLDER')}
                        optionFilterProp="children"
                        loading={loadingUsers}
                        filterOption={(input, option) =>
                          (option!.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onUserChange}
                        disabled={locked}
                      >
                        {users.map((user) => {
                          const { recordId, firstname, lastname } = user;
                          return (
                            <Option key={`user_${recordId}`} value={`${recordId}`}>
                              {`${firstname} ${lastname}`}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={t('CONTRACTS.FORM.TEMPLATE')}
                      name="templateId"
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(50% - 5px)' : '100%',
                      }}
                    >
                      <Select
                        allowClear
                        getPopupContainer={(trigger) => trigger}
                        loading={loadingContractTemplates}
                        showSearch
                        placeholder={t('CONTRACTS.FORM.TEMPLATE_PLACEHOLDER')}
                        optionFilterProp="children"
                        onChange={onWantToChangeTemplateId}
                        filterOption={(input, option) =>
                          (option!.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={locked}
                      >
                        {contractTemplates.map((template) => {
                          const { name, id } = template;
                          return (
                            <Option key={`template_${id}`} value={`${id}`}>
                              {name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                      label={t('GLOBAL.START_PERIOD')}
                      name="start"
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(50% - 5px)' : '100%',
                        marginRight: '10px',
                      }}
                      rules={[{ required: true }]}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            start: e,
                          });
                        }}
                        disabled={locked}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t('CONTRACTS.FORM.END')}
                      name="end"
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(50% - 5px)' : '100%',
                      }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            end: e,
                          });
                        }}
                        disabled={locked}
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item name="signatureId" label={t('CONTRACTS.FORM.SIGNATORY')} rules={[{ required: true }]}>
                    <Select
                      allowClear
                      getPopupContainer={(trigger) => trigger}
                      showSearch
                      loading={loadingSignatures}
                      placeholder={t('CONTRACTS.FORM.SIGNATORY_PLACEHOLDER')}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option!.children as any)?.join().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={locked}
                    >
                      {signatures.map((signature) => {
                        const { name, id, title } = signature;
                        return (
                          <Option key={`signatory_${id}`} value={`${id}`}>
                            {name} - {title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {(!locked || (locked && fileName)) && (
                    <Form.Item label={t('DOCUMENTS.CONTRACTS.UPLOAD_CONTRACT')}>
                      <div className="upload-file-container">
                        <div className={`left ${locked ? 'locked' : ''}`}>
                          <Upload
                            beforeUpload={beforeUpload}
                            accept=".docx"
                            maxCount={1}
                            showUploadList={false}
                            className="browse-btn-container"
                            disabled={locked}
                          >
                            <div className="content">
                              <button type="button" className="browse-btn">
                                {file ? file.name : fileName ? fileName : t('GLOBAL.BROWSE')}
                              </button>
                            </div>
                          </Upload>
                          {file || fileName ? <i className="icon-plus" onClick={onClearTemplate} /> : null}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <button type="button" style={{ textDecoration: 'underline' }} onClick={onVariablesUsage}>
                          {t('DOCUMENTS.VARIABLES.TITLE')}
                        </button>
                      </div>
                    </Form.Item>
                  )}
                </div>
              </Col>
              <Col span={windowSize.innerWidth > 900 ? 12 : 24}>
                <div className="card-shadow">
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                      name="hoursWeek"
                      label={t('CONTRACTS.FORM.WEEK_HOURS')}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                        marginRight: 10,
                      }}
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        placeholder={t('CONTRACTS.FORM.WEEK_HOURS')}
                        type="number"
                        min={0}
                        style={{ width: '100%' }}
                        step="1"
                        parser={(value) => {
                          return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                        }}
                        disabled={locked}
                      />
                    </Form.Item>
                    <Form.Item
                      name="salaryAmountGross"
                      label={t('GLOBAL.GROSS_COST')}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? `calc(33% - 5px)` : '100%',
                        marginRight: 10,
                      }}
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        type="number"
                        min={0}
                        style={{ width: '100%' }}
                        step="0.0001"
                        parser={(value) => {
                          return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                        }}
                        disabled={locked}
                      />
                    </Form.Item>
                    <Form.Item
                      name="salaryPeriod"
                      label={t('CONTRACTS.FORM.SALARY_PERIOD')}
                      rules={[{ required: true }]}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                      }}
                    >
                      <Select
                        allowClear
                        getPopupContainer={(trigger) => trigger}
                        placeholder={t('CONTRACTS.FORM.SALARY_PERIOD')}
                        optionFilterProp="children"
                        disabled={locked}
                      >
                        <Option value="hours">{t('GLOBAL.HOUR')}</Option>
                        <Option value="days">{t('GLOBAL.DAY')}</Option>
                        <Option value="weeks">{t('GLOBAL.WEEK')}</Option>
                        <Option value="months">{t('GLOBAL.MONTH')}</Option>
                      </Select>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                      name="daysWeek"
                      label={t('CONTRACTS.FORM.WEEK_DAYS')}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                        marginRight: 10,
                      }}
                    >
                      <InputNumber type="number" min={0} style={{ width: '100%' }} disabled={locked} />
                    </Form.Item>
                    <Form.Item
                      name="daysOffYear"
                      label={t('CONTRACTS.FORM.DAYOFFS_NUMBER_BY_YEAR')}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                        marginRight: 10,
                      }}
                    >
                      <InputNumber type="number" min={0} style={{ width: '100%' }} disabled={locked} />
                    </Form.Item>
                    <Form.Item
                      name="maxHoursDay"
                      label={t('CONTRACTS.FORM.MAX_DAILY_HOURS')}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                      }}
                    >
                      <InputNumber type="number" min={0} style={{ width: '100%' }} disabled={locked} />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    name="workingTimePlan"
                    label={<span style={{ display: 'flex', alignItems: 'center' }}>{t('GLOBAL.REGIME')}</span>}
                    style={{
                      display: 'inline-block',
                      width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                    }}
                  >
                    <Select
                      allowClear
                      getPopupContainer={(trigger) => trigger}
                      placeholder={t('GLOBAL.REGIME')}
                      optionFilterProp="children"
                      disabled={locked}
                    >
                      {REGIME_TYPES.map((type) => {
                        return (
                          <Select.Option key={`regime_${type}`} value={type!}>
                            {t(`CONTRACTS.REGIME_TYPES.${type}`)}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {isFeatureEnabled(features, FEATURES.AUTOMATIC_DIMONA_CONTRACTS) && (
                    <Form.Item style={{ marginBottom: 0, display: 'flex', alignItems: 'center' }}>
                      <Form.Item
                        name="autorenew"
                        valuePropName="checked"
                        style={{
                          display: 'inline-block',
                          marginRight: 10,
                          width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                        }}
                      >
                        <Checkbox disabled={!!activeContract && !activeContract.autorenew && !!activeContract.sentAt}>
                          {t('documents.contracts.auto-renew')}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.autorenew !== curValues.autorenew ||
                          prevValues.renewPeriod !== curValues.renewPeriod
                        }
                        style={{
                          display: 'inline-block',
                          margin: 0,
                          width: windowSize.innerWidth > 900 ? 'calc(66% - 5px)' : '100%',
                        }}
                      >
                        {(form) => {
                          const autorenew = form.getFieldValue('autorenew');
                          const renewPeriod = form.getFieldValue('renewPeriod');
                          if (autorenew) {
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  gap: 10,
                                }}
                              >
                                <Form.Item
                                  name="renewPeriod"
                                  style={{ display: 'inline-block', marginBottom: 0, flex: 1 }}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    allowClear
                                    placeholder={t('documents.contracts.fields.renewal.recurrence.title')}
                                    optionFilterProp="children"
                                    dropdownMatchSelectWidth={false}
                                    disabled={locked}
                                  >
                                    <Option value="same_days">
                                      {t('documents.contracts.fields.renewal.recurrence.options.same-days.title')}
                                    </Option>
                                    <Option value="daily">
                                      {t('documents.contracts.fields.renewal.recurrence.options.daily.title')}
                                    </Option>
                                    <Option value="weekly">
                                      {t('documents.contracts.fields.renewal.recurrence.options.weekly.title')}
                                    </Option>
                                    <Option value="bi_weekly">
                                      {t('documents.contracts.fields.renewal.recurrence.options.bi-weekly.title')}
                                    </Option>
                                    <Option value="monthly">
                                      {t('documents.contracts.fields.renewal.recurrence.options.monthly.title')}
                                    </Option>
                                    <Option value="bi_monthly">
                                      {t('documents.contracts.fields.renewal.recurrence.options.bi-monthly.title')}
                                    </Option>
                                    <Option value="quarterly">
                                      {t('documents.contracts.fields.renewal.recurrence.options.quarterly.title')}
                                    </Option>
                                    <Option value="semester">
                                      {t('documents.contracts.fields.renewal.recurrence.options.semester.title')}
                                    </Option>
                                    <Option value="yearly">
                                      {t('documents.contracts.fields.renewal.recurrence.options.yearly.title')}
                                    </Option>
                                  </Select>
                                </Form.Item>
                                {['weekly', 'monthly', 'quarterly', 'yearly'].includes(renewPeriod) ? (
                                  <Form.Item
                                    name="renewOption"
                                    style={{ display: 'inline-block', marginBottom: 0, flex: 1 }}
                                    rules={[{ required: true }]}
                                  >
                                    <Select
                                      allowClear
                                      placeholder={t('documents.contracts.fields.renewal.period.title')}
                                      optionFilterProp="children"
                                      dropdownMatchSelectWidth={false}
                                      disabled={locked}
                                    >
                                      <Option value="same_dates">
                                        <Tooltip
                                          overlay={t(
                                            'documents.contracts.fields.renewal.period.options.same-dates.tooltip',
                                          )}
                                          placement="left"
                                          mouseEnterDelay={0}
                                          mouseLeaveDelay={0}
                                        >
                                          <span style={{ display: 'inline-block', width: '100%' }}>
                                            {t('documents.contracts.fields.renewal.period.options.same-dates.title')}
                                          </span>
                                        </Tooltip>
                                      </Option>
                                      {renewPeriod == 'weekly' && (
                                        <Option value="full_week">
                                          <Tooltip
                                            overlay={t(
                                              'documents.contracts.fields.renewal.period.options.full-week.tooltip',
                                            )}
                                            placement="left"
                                            mouseEnterDelay={0}
                                            mouseLeaveDelay={0}
                                          >
                                            <span style={{ display: 'inline-block', width: '100%' }}>
                                              {t('documents.contracts.fields.renewal.period.options.full-week.title')}
                                            </span>
                                          </Tooltip>
                                        </Option>
                                      )}

                                      {renewPeriod == 'monthly' && (
                                        <Option value="full_month">
                                          <Tooltip
                                            overlay={t(
                                              'documents.contracts.fields.renewal.period.options.full-month.tooltip',
                                            )}
                                            placement="left"
                                            mouseEnterDelay={0}
                                            mouseLeaveDelay={0}
                                          >
                                            <span style={{ display: 'inline-block', width: '100%' }}>
                                              {t('documents.contracts.fields.renewal.period.options.full-month.title')}
                                            </span>
                                          </Tooltip>
                                        </Option>
                                      )}

                                      {renewPeriod == 'quarterly' && (
                                        <Option value="full_quarter">
                                          <Tooltip
                                            overlay={t(
                                              'documents.contracts.fields.renewal.period.options.full-quarter.tooltip',
                                            )}
                                            placement="left"
                                            mouseEnterDelay={0}
                                            mouseLeaveDelay={0}
                                          >
                                            <span style={{ display: 'inline-block', width: '100%' }}>
                                              {t(
                                                'documents.contracts.fields.renewal.period.options.full-quarter.title',
                                              )}
                                            </span>
                                          </Tooltip>
                                        </Option>
                                      )}

                                      {renewPeriod == 'yearly' && (
                                        <Option value="full_year">
                                          <Tooltip
                                            overlay={t(
                                              'documents.contracts.fields.renewal.period.options.full-year.tooltip',
                                            )}
                                            placement="left"
                                            mouseEnterDelay={0}
                                            mouseLeaveDelay={0}
                                          >
                                            <span style={{ display: 'inline-block', width: '100%' }}>
                                              {t('documents.contracts.fields.renewal.period.options.full-year.title')}
                                            </span>
                                          </Tooltip>
                                        </Option>
                                      )}
                                    </Select>
                                  </Form.Item>
                                ) : (
                                  <Form.Item style={{ display: 'inline-block', marginBottom: 0, flex: 1 }}></Form.Item>
                                )}
                              </div>
                            );
                          }
                        }}
                      </Form.Item>
                    </Form.Item>
                  )}
                  {activeUserParams?.dimona && isFeatureEnabled(features, FEATURES.DIMONA) && (
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Form.Item
                        name="dimona"
                        valuePropName="checked"
                        style={{
                          display: 'inline-block',
                          width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                          marginRight: 10,
                        }}
                      >
                        <Checkbox disabled={locked}>{t('DIMONA.CREATE_DIMONA')}</Checkbox>
                      </Form.Item>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => prevValues.dimona !== curValues.dimona}
                        style={{ display: 'inline-block', margin: 0 }}
                      >
                        {(form) => {
                          const dimona = form.getFieldValue('dimona');
                          if (dimona) {
                            return (
                              <Form.Item
                                name="workerType"
                                rules={[{ required: true }]}
                                style={{ display: 'inline-block', width: '100%' }}
                              >
                                <Select
                                  allowClear
                                  getPopupContainer={(trigger) => trigger}
                                  placeholder={t('FORMS.WORKER_TYPE_PLACEHOLDER')}
                                  dropdownMatchSelectWidth={false}
                                  disabled={locked}
                                >
                                  {DIMONA_WORKER_TYPES.map((workerType) => {
                                    return (
                                      <Option key={`userType_${workerType}`} value={`${workerType}`}>
                                        {t(`DIMONA.WORKTYPES.${workerType}`)}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            );
                          }
                        }}
                      </Form.Item>
                      {!hideDimonaExtra && (
                        <Form.Item
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.workerType !== curValues.workerType || prevValues.dimona !== curValues.dimona
                          }
                          style={{ margin: 0 }}
                        >
                          {(form) => {
                            const dimona = form.getFieldValue('dimona');
                            const workerType = form.getFieldValue('workerType');
                            if (dimona && workerType === 'STU') {
                              return (
                                <>
                                  <Form.Item
                                    name="dimonaPlannedHours"
                                    label={t('GLOBAL.HOURS_NUMBER')}
                                    rules={[{ required: true }]}
                                  >
                                    <InputNumber type="number" min={1} style={{ width: '100%' }} disabled={locked} />
                                  </Form.Item>
                                  {placesOfWork.length > 0 && (
                                    <Form.Item
                                      label={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                          {t('DIMONA.PLACE_OF_WORK')}
                                        </span>
                                      }
                                      name="placeOfWork"
                                    >
                                      <Select
                                        placeholder={t('DIMONA.PLACE_OF_WORK')}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                          (option!.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        disabled={locked}
                                      >
                                        <Select.Option key={`dimona-no-place-of-work`} value={'null'}>
                                          {t('DIMONA.NO_PLACE_OF_WORK')}
                                        </Select.Option>
                                        {placesOfWork.map((place) => (
                                          <Select.Option key={place} value={place}>
                                            {place}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  )}
                                </>
                              );
                            }
                          }}
                        </Form.Item>
                      )}
                    </Form.Item>
                  )}
                </div>
              </Col>
            </Row>
            {!file && !fileName && !locked ? (
              <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={htmlContent}
                init={{
                  height: 600,
                  menubar: false,
                  setup: function (editor) {
                    editor.ui.registry.addMenuButton('placeholders', {
                      text: 'Variables',
                      fetch: (callback) => {
                        callback(
                          variablesObjectToArray(placeholdersRef.current)?.map(({ id, label }: any) => ({
                            type: 'menuitem',
                            text: label,
                            onAction: function () {
                              editor.insertContent(
                                `<code data-key="${id}" class="mceNonEditable" contenteditable="false">${label}</code>`,
                              );
                            },
                          })),
                        );
                      },
                    });
                  },
                  plugins: TINYMCE_PLUGINS,
                  toolbar: TINYMCE_TOOLBAR,
                  content_style: TINYMCE_CONTENT_STYLE,
                }}
              />
            ) : (
              <>
                {previewPdf ? (
                  <iframe src={previewPdf} width="100%" height="800px" />
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Spin spinning={!previewPdf} />
                    <span>{t('DOCUMENTS.PREVIEWS.LOADING')}</span>
                  </div>
                )}
              </>
            )}
            {!locked && (
              <div className={`actions active`}>
                <Button loading={loading} type="primary" htmlType="submit">
                  {t('GLOBAL.SAVE')}
                </Button>
              </div>
            )}
          </Form>
        </Spin>
      </TableView>
    </>
  );
};

const ContractStyled = styled(Contract)`
  background: ${colors.blueExtraLight};
  border-radius: 3px;

  label {
    width: 100%;
  }

  .ant-form-item-label {
    padding: 0 !important;
  }

  .ant-form-item-label > label {
    font-size: unset;
    padding: 0;
  }

  .upload-file-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      border-radius: 5px;
      background-color: #f6f8fc;
      border: 1px solid #e2e6ef;
      &.locked {
        background-color: #f5f5f5 !important;
      }
      .icon-plus {
        transform: rotate(45deg);
        padding: 0 15px;
        cursor: pointer;
      }
    }
    .browse-btn-container {
      flex: 1;
      .ant-upload-select {
        width: 100%;
        .browse-btn {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          width: 100%;
        }
      }
    }
  }

  .ant-row {
    margin-bottom: 20px;
  }
  .contract-container {
    padding: 0 25px 25px;
    .ant-col .card-shadow {
      padding: 0 20px;
    }
  }
  .tox-tinymce {
    margin-bottom: 40px;
  }
  .card-shadow {
    padding: 20px !important;
    justify-content: flex-start;
  }
  .actions {
    position: fixed;
    width: calc(100vw - 160px);
    background: white;
    bottom: -80px;
    margin: 0;
    margin-left: 40px;
    display: flex;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
    padding: 10px;
    border-radius: 3px 3px 0 0;
    transition: bottom 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    &.active {
      transition-timing-function: ease-in;
      bottom: 0px;
    }
  }
`;

export default ContractStyled;
