import RangePickerExtraFooter from '@/pages/app/components/RangePickerExtraFooter';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { DIMONA_WORK_TYPES, IDimonaStatementFilters, IV4Dimona } from '@/types/dimona.model';
import { IUser } from '@/types/user.model';
import { Badge, Button, DatePicker, Input, Modal, Select, Tooltip } from 'antd';
import { SelectValue } from 'antd/es/select';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  user?: IUser;
  dates: [Moment, Moment];
  filters: IDimonaStatementFilters;
  dimonaErrors: IV4Dimona[];
  dimonaPending: IV4Dimona[];
  onCreateDimona: () => void;
  onUpdateFromOnss: () => void;
  setDates: React.Dispatch<React.SetStateAction<[Moment, Moment]>>;
  setFilters: React.Dispatch<React.SetStateAction<IDimonaStatementFilters>>;
}

const Header: React.FC<Props> = ({
  className,
  user,
  dates,
  filters,
  dimonaErrors,
  dimonaPending,
  onCreateDimona,
  onUpdateFromOnss,
  setDates,
  setFilters,
}) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { users, loadingUsers, loadingUserCategories },
  } = useContext(AppContext);

  const [states, setStates] = useState(['active']);

  useEffect(() => {
    if (!user || !user.recordId) return;
    onUsersChange([user.recordId]);
  }, [user]);

  const onWantUpdateFromOnss = () => {
    Modal.confirm({
      title: t('GLOBAL.WARNING'),
      type: 'error',
      content: t('DIMONA.MODAL_UPDATE_FROM_ONSS.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: 'Ok',
      okType: 'danger',
      onOk: () => {
        onUpdateFromOnss();
      },
      onCancel: () => {},
    });
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, search: e.target.value });
  };

  const onUsersChange = (users: SelectValue) => {
    setFilters({ ...filters, users: users as string[] });
  };

  const onFilterChange = (filter: SelectValue) => {
    setFilters({ ...filters, filter: filter as string });
  };

  const onTypesChange = (types: SelectValue) => {
    setFilters({ ...filters, types: types as string[] });
  };
  
  const onClear = () => {
    onStatesChange(['active'])
    setFilters({
      search: '',
      states: ['active'],
      types: [],
      users: [],
      errors: false,
      pending: false,
    });
  }

  const onStatesChange = (selectedStates: string[]) => {
    const newStates = selectedStates.length > 0 ? selectedStates : ['active'];

    setStates(newStates);
    setFilters({ ...filters, states: newStates, errors: newStates.includes('errors'), pending: newStates.includes('pending')});
  };

  return (
    <header className={className}>
      <div className="top">
        <div className="left">{!user && <h2>{t('DIMONA.TITLE')}</h2>}</div>
        <div className="right">
          <div className="actions">
            <Button type="primary" onClick={onCreateDimona}>
              {t('DIMONA.NEW_DECLARATION')}
            </Button>
            {!user && (
              <React.Fragment>
                <Tooltip title={t('DIMONA.DOWNLOAD_ONSS_TOOLTIP')}>
                  <Button
                    type="default"
                    onClick={onWantUpdateFromOnss}
                    style={{ borderColor: colors.grey, color: colors.grey }}
                  >
                    {t('DIMONA.UPDATE_ONSS')}
                  </Button>
                </Tooltip>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <div className="bottom">
        <DatePicker.RangePicker
          allowClear={false}
          getPopupContainer={(trigger) => trigger}
          format={'L'}
          value={dates}
          onChange={(dates: any) => setDates(dates)}
          renderExtraFooter={() => <RangePickerExtraFooter setRangePickerValues={setDates} lastYear={false} />}
        />
        {!user && (
          <Input
            className="input"
            allowClear
            placeholder={t('GLOBAL.SEARCH')}
            prefix={<i className="icon-search" />}
            onChange={onSearchChange}
            value={filters.search}
          />
        )}
        {!user && (
          <div>
            <Select
              allowClear={true}
              showSearch
              optionFilterProp="children"
              placeholder={
                <span>
                  <i className="icon-user" style={{ color: 'black', marginRight: 5 }} />
                  {t('GLOBAL.USER')}
                </span>
              }
              loading={loadingUsers}
              disabled={loadingUsers}
              filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              style={{ width: 250 }}
              dropdownMatchSelectWidth={false}
              mode="multiple"
              maxTagCount={1}
              onChange={onUsersChange}
              value={filters.users}
            >
              {users.map((user) => (
                <Select.Option key={user.recordId!} value={user.recordId!}>
                  {user.displayName}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        <Select
          allowClear={true}
          optionFilterProp="children"
          dropdownMatchSelectWidth={false}
          style={{ minWidth: 200 }}
          placeholder={
            <span>
              <i className="icon-filter" style={{ color: 'black', marginRight: 5 }} />
              {t('DIMONA.CLOCKING_FILTER')}
            </span>
          }
          onChange={onFilterChange}
          value={filters.filter}
        >
          <Select.Option key="all" value="all">
            {t('GLOBAL.ALL_FEMININE')}
          </Select.Option>
          <Select.Option key="not-scheduled" value="not-scheduled">
            {t('DIMONA.DIMONA_NOT_PLANNED')}
          </Select.Option>
          <Select.Option key="scheduled" value="scheduled">
            {t('DIMONA.DIMONA_PLANNED')}
          </Select.Option>
        </Select>
        <Select
          optionFilterProp="children"
          mode="multiple"
          placeholder={
            <span>
              <i className="icon-ok" style={{ color: 'black', marginRight: 5 }} />
              {t('GLOBAL.STATE')}
            </span>
          }
          maxTagCount={1}
          dropdownMatchSelectWidth={false}
          style={{ minWidth: 200 }}
          value={states}
          onChange={onStatesChange}
        >
          <Select.Option key="active" value="active">
            {t('DIMONA.STATUSES.ACTIVE')}
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            {t('DIMONA.STATUSES.INACTIVE')}
          </Select.Option>
          <Select.Option key="locked" value="locked">
            {t('DIMONA.STATUSES.LOCKED')}
          </Select.Option>
          <Select.Option key="pending" value="pending">
            {t('DIMONA.STATUSES.PENDING')}{' '}
            <Badge count={dimonaPending.length} style={{ backgroundColor: colors.orange }} />
          </Select.Option>
          <Select.Option key="errors" value="errors">
            {t('DIMONA.STATUSES.ERROR')} <Badge count={dimonaErrors.length} style={{ backgroundColor: colors.red }} />
          </Select.Option>
          <Select.Option key="cancelled" value="cancelled">
            {t('DIMONA.STATUSES.CANCELLED')}
          </Select.Option>
        </Select>
        <Button onClick={onClear}>{t('GLOBAL.CLEAR')}</Button>
      </div>
    </header>
  );
};

export default styled(Header)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 10px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .filters {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    .filter {
      background-color: #efefef;
      border-radius: 8px;
      padding: 3px 12px;
      position: relative;
    }

    .filter .icon-cancel {
      display: none;
      transition: display 0.2s ease;
    }

    .filter:hover .icon-cancel {
      display: inline-block;
    }
  }

  .ant-input-affix-wrapper {
    width: 190px !important;
    margin-left: 0px;
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 10px;

    .input {
      width: 190px !important;
    }
    .new {
      width: 160px;
    }
  }

  .left {
    display: flex;
    align-items: center;
    gap: 10px;

    h2 {
      margin: 0 !important;
    }
  }

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .ant-input-affix-wrapper {
      width: 100% !important;
      margin-left: 0;
    }

    .left {
      flex-direction: column;
      gap: 10px;

      h2 {
        margin-left: 0 !important;
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      gap: 10px;

      button {
        margin-left: 0 !important;
      }

      .ant-divider {
        display: none;
      }

      .new {
        width: 100%;
        margin-left: 0 !important;
      }

      .input {
        width: 100%;
      }
    }

    .select {
      width: 100% !important;
    }
  }
`;

interface RangePickerFooterProps {
  setValues: React.Dispatch<React.SetStateAction<[Moment, Moment]>>;
}
const RangePickerFooter: React.FC<RangePickerFooterProps> = ({ setValues }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const changeDate = (start: Moment, end: Moment) => {
    setValues([start, end]);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 10, margin: '10px 0' }}>
      <Button onClick={() => changeDate(moment().startOf('day'), moment().endOf('day'))}>{t('GLOBAL.TODAY')}</Button>
      <Button
        onClick={() =>
          changeDate(moment().startOf('day').subtract(1, 'day'), moment().endOf('day').subtract(1, 'days'))
        }
      >
        {t('GLOBAL.YESTERDAY')}
      </Button>
      <Button
        onClick={() =>
          changeDate(moment().startOf('day').subtract(8, 'day'), moment().endOf('day').subtract(1, 'days'))
        }
      >
        {t('GLOBAL.LAST_7_DAYS')}
      </Button>
      <Button
        onClick={() =>
          changeDate(moment().startOf('week').subtract(1, 'week'), moment().endOf('week').subtract(1, 'week'))
        }
      >
        {t('REPORTS.CONTROL_OF_SERVICES.WEEK-1')}
      </Button>
      <Button onClick={() => changeDate(moment().startOf('month'), moment().endOf('month'))}>
        {t('GLOBAL.THIS_MONTH')}
      </Button>
      <Button
        onClick={() =>
          changeDate(
            moment().startOf('month').subtract(1, 'month'),
            moment().startOf('month').subtract(1, 'month').endOf('month'),
          )
        }
      >
        {t('GLOBAL.LAST_MONTH')}
      </Button>
    </div>
  );
};
