import React, { FC } from 'react';
import moment from 'moment/moment';
import { Moment } from 'moment';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

type LineChartProps = {
  data: {
    lastYear: any;
    real: any;
  } | null;
  loading: boolean;
  startDate: Moment;
};

const LineChart: FC<LineChartProps> = ({ data, loading, startDate }) => {
  const firstDay = moment(startDate).startOf('month');
  const lastDay = moment(startDate).endOf('month');
  const labels = Array.from({ length: lastDay.diff(firstDay, 'day') + 1 }).map((_, index) =>
    moment(firstDay).add(index, 'day').format('D MMM'),
  );

  if (loading || !data) {
    return null;
  }

  return (
    <Line
      data={{
        labels: labels,
        datasets: [
          {
            label: moment(startDate).subtract(1, 'year').format('YYYY'),
            data: Object.entries(data.lastYear).map((data: any) => ({
              x: moment.unix(data[0]).add(1, 'year'),
              y: data[1],
            })),
            backgroundColor: 'rgba(255,0,0,0.3)',
            fill: true,
          },
          {
            label: startDate.format('YYYY'),
            data: Object.entries(data.real).map((data: any) => ({ x: moment.unix(data[0]), y: data[1] })),
            backgroundColor: 'rgba(0,0,255,0.3)',
            fill: true,
          },
        ],
      }}
      options={{
        spanGaps: true,
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0.5,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      }}
    />
  );
};

export default LineChart;
