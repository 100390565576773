import api from '@/api';
import {
  CreateScheduleCommentPayload,
  UpdateScheduleCommentPayload,
  V4BirthdayResponse,
  V4CommentResponse,
  V4WeatherResponse
} from '../types';

export const getComments = async (startDate: string, endDate: string) => {
  const { response } = await api<{ data: V4CommentResponse[] }>(
    `schedule/day-comments?startDate=${startDate}&endDate=${endDate}`,
  );
  return response.data;
};

export const createComment = async (payload: CreateScheduleCommentPayload) => {
  const { response } = await api<{ data: CreateScheduleCommentPayload }>(`day-comments`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return response.data;
};

export const updateComment = async (commentId: string, payload: UpdateScheduleCommentPayload) => {
  const { response } = await api<{ data: UpdateScheduleCommentPayload }>(`day-comments/${commentId}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });

  return response.data;
};

export const deleteComment = async (commentId: string) => {
  const { response } = await api<null>(`day-comments/${commentId}`, {
    method: 'DELETE',
  });

  return response;
};

export const getWeather = async () => {
  const { response } = await api<{ data: V4WeatherResponse[] }>(`schedule/weather`);
  return response.data;
};

export const getBirthdays = async (startDate: string, endDate: string) => {
  const { response } = await api<{
    data: V4BirthdayResponse[];
  }>(`schedule/birthdays?startDate=${startDate}&endDate=${endDate}`);
  return response.data;
};
