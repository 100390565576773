import AppContext from '@/pages/app/context';
import { default as Colors, default as colors } from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { IScheduleDay } from '@/types/schedule-day.model';
import { getContrast, getDatesBetweenDates, isNullOrUndefined, minutesToHoursAndOrMinutes } from '@/utils';
import { Affix, Popover } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ITotalRole } from '..';
import { InitialStateType } from '../../redux/store';
import ComparisonDetails from './ComparisonDetails';
import ProductivityIndexRealDetailsStyled from './ProductivityIndexRealDetails';
import TotalHoursDetails from './TotalHoursDetails';

interface Props {
  className?: string;
  totalByRole: ITotalRole[][];
  selectedHeadcounts: number[];
  days: IScheduleDay[];
  activeSection?: string;
  refTotalHours: React.MutableRefObject<null> | null;
  refPageContainer: React.MutableRefObject<null> | null;
  currency: string;
  comparison: boolean;
  department: IDepartment | null;
  turnover?: any;
}

const TotalHours: React.FC<Props> = ({
  className,
  days,
  activeSection,
  totalByRole,
  refTotalHours,
  refPageContainer,
  currency,
  comparison,
  department,
  selectedHeadcounts,
  turnover,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const {
    showHeadCount,
    showShifts,
    showAbsences,
    showOtherDepartments,
    showProductivityIndex,
    filterOptions,
    picker,
    shiftsByUsers,
    startDate,
    endDate,
    usersMap,
  } = useSelector(
    ({
      showShifts,
      showAbsences,
      showHeadCount,
      showOtherDepartments,
      showProductivityIndex,
      filterOptions,
      picker,
      shiftsByUsers,
      startDate,
      endDate,
      usersMap,
    }: InitialStateType) => ({
      showHeadCount,
      showShifts,
      showAbsences,
      showOtherDepartments,
      showProductivityIndex,
      filterOptions,
      picker,
      shiftsByUsers,
      startDate,
      endDate,
      usersMap,
    }),
    shallowEqual,
  );
  const [affixed, setAffixed] = useState(false);
  const {
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });
  const { pathname } = useLocation();
  const {
    state: { activeDepartment, quotaTemplates, sections, quotaCustomTemplates },
  } = useContext(AppContext);
  const month_view = pathname.startsWith('/app/hours/manage/month');

  let shyftTypes = [1];
  if (showAbsences) {
    shyftTypes = [1, 2];
  } else {
    shyftTypes = [1];
  }

  let totalOfTotalMinutes = 0;
  let totalOfTotalPrices = 0;
  const detailsOfTotalByRole: ITotalRole[] = [];
  totalByRole.forEach((day) => {
    day.forEach((role) => {
      const { minutes, price, name, id, color, background } = role;
      const totalDetail = detailsOfTotalByRole.find((x) => x.id === role.id);
      if (totalDetail) {
        totalDetail.minutes += minutes;
        totalDetail.price += price;
      } else {
        detailsOfTotalByRole.push({
          id,
          name,
          color,
          background,
          minutes,
          price,
        });
      }
      totalOfTotalMinutes += minutes;
      totalOfTotalPrices += price;
    });
  });

  const globalDetailsOfTotalByRole: ITotalRole[] = [];
  days.forEach((day) => {
    day.totalsByUserCategory?.forEach((totalByUserCategory) => {
      const { minutes, price, name, id, color, background } = totalByUserCategory;
      const totalDetail = globalDetailsOfTotalByRole.find((x) => x.id === totalByUserCategory.id);
      if (totalDetail) {
        totalDetail.minutes += minutes;
        totalDetail.price += price;
      } else {
        globalDetailsOfTotalByRole.push({
          id,
          name,
          color,
          background,
          minutes,
          price,
        });
      }
    });
  });

  const handleAffixed = (affixed: boolean) => {
    setAffixed(affixed);
    setTimeout(() => {
      const element = refPageContainer!.current! as HTMLElement;
      if (element) {
        element.dispatchEvent(new Event('scroll'));
      }
    });
  };

  const globalTotalHoursInMinute = days.reduce((a, b) => a + (b.hoursInMinute || 0), 0);
  const globalTotalBudget = days.reduce((a, b) => a + (b.budget || 0), 0);
  const hasGlobalTotalTargetHoursInMinute = days.some((d) => d.target.hoursInMinute);
  const globalTotalTargetHoursInMinute = days.reduce((a, b) => a + (b.target.hoursInMinute || 0), 0);
  const globalTotalTargetBudget = days.reduce((a, b) => a + (b.target?.budget || 0), 0);
  let globalTotalHoursIndicator = '';
  if (hasGlobalTotalTargetHoursInMinute) {
    if (globalTotalHoursInMinute > globalTotalTargetHoursInMinute) {
      globalTotalHoursIndicator = 'red';
    } else if (globalTotalHoursInMinute < globalTotalTargetHoursInMinute) {
      globalTotalHoursIndicator = 'blue';
    }
  }

  if (
    !department?.scheduleParams?.sidebarCounters &&
    !department?.scheduleParams?.global_stats &&
    !department?.scheduleParams?.filter_stats
  ) {
    return null;
  }

  const productivityCalculationType = activeDepartment?.scheduleParams?.productivity_calculation_type || 'HOURS_WORKED';
  const productivityCoefficient = activeDepartment?.scheduleParams?.coefficient || 1;
  const productivityDefaultHours = activeDepartment?.scheduleParams?.default_hours || 0;
  const shiftsByUsersWithoutFreeShifts = shiftsByUsers.map(({ user, shifts }) => ({
    user,
    shifts: shifts
      .filter(
        (shift) =>
          (!activeDepartment?.scheduleParams?.count_free_shift_in_head_count &&
            shift.shyftType &&
            shyftTypes.includes(shift.shyftType) &&
            shift.userRecordId !== null) ||
          (activeDepartment?.scheduleParams?.count_free_shift_in_head_count &&
            shift.shyftType &&
            shyftTypes.includes(shift.shyftType)) ||
          shift.userRecordId === null,
      )
      .filter((shift) => (shift.shyftType !== 2 ? true : shift.dayoff && shift.dayoff.paid == true ? true : false))
      .filter((shift) => shift.hide == false),
  }));
  const weekDays = getDatesBetweenDates(startDate, endDate);
  const totalHours = shiftsByUsersWithoutFreeShifts.reduce((result: any, item) => {
    item.shifts.forEach((shift) => {
      let shiftStart = moment.unix(shift.start!);
      let shiftEnd = moment.unix(shift.end!);
      const dayKey = shiftStart.format('YYYY-MM-DD');
      const turnoverOfDay = turnover ? turnover[moment(dayKey).startOf('day').unix()] : null;
      const unpaidPause = shift.pause?.unpaid;
      const paidPause = shift.pause?.paid;
      const durationInHours = shiftEnd.diff(shiftStart, 'hours', true);

      if (shiftStart.isSameOrAfter(startDate, 'day')) {
        const workTime = shift.clocking
          ? shift.clocking.reduce((value, clocking) => {
              if (!clocking.end) {
                return value + 0;
              }
              return value + (clocking.worktime ? clocking.worktime / 60 : 0 || 0);
            }, 0)
          : shift.shyftType == 1
          ? durationInHours - (unpaidPause ? unpaidPause / 3600 : 0) + (paidPause ? paidPause / 3600 : 0)
          : durationInHours - (unpaidPause ? unpaidPause / 3600 : 0) + (paidPause ? paidPause / 3600 : 0) * 60;

        if (turnoverOfDay && turnoverOfDay.type == 'real' && !shift.approved) {
          result[dayKey] = (result[dayKey] || 0) + 0;
        } else {
          result[dayKey] = (result[dayKey] || 0) + workTime;
        }
      }
    });

    weekDays.forEach((day) => {
      if (!result[day]) {
        result[day] = 0;
      }
    });

    return Object.keys(result)
      .sort()
      .reduce((sortedResult: any, key) => {
        sortedResult[key] = result[key];
        return sortedResult;
      }, {});
  }, {});
  const shiftsCost = shiftsByUsersWithoutFreeShifts.reduce((result: any, item) => {
    item.shifts.forEach((shift) => {
      const shiftMoment = moment.unix(shift.start!);

      // Check if the shift is in the current week
      if (shiftMoment.isSameOrAfter(startDate, 'day')) {
        const dayKey = shiftMoment.format('YYYY-MM-DD');
        const turnoverOfDay = turnover ? turnover[moment(dayKey).startOf('day').unix()] : null;
        const user = shift.userRecordId ? usersMap.get(shift.userRecordId!) : null;

        const price =
          shift.clocking && user && user.hourlyRate
            ? shift.clocking.reduce((result, item) => {
                if (!item.worktime) return result;
                if (!user.hourlyRate) return result;
                return result + (item.worktime / 60) * user.hourlyRate;
              }, 0)
            : shift.price;

        if (turnoverOfDay && turnoverOfDay.type == 'real' && !shift.approved) {
          result[dayKey] = (result[dayKey] || 0) + 0;
        } else {
          result[dayKey] = (result[dayKey] || 0) + price;
        }
      }
    });

    weekDays.forEach((day) => {
      if (!result[day]) {
        result[day] = 0;
      }
    });

    return Object.keys(result)
      .sort()
      .reduce((sortedResult: any, key) => {
        sortedResult[key] = result[key];
        return sortedResult;
      }, {});
  }, {});

  const section = activeSection ? sections.find((section) => section.id == activeSection) : null;

  return (
    <Affix
      target={() => document.getElementsByClassName('app-hours-container')[0] as HTMLElement}
      onChange={(affixed) => handleAffixed(!!affixed)}
      offsetTop={45}
      className="no-print"
    >
      <div ref={refTotalHours} className={`${className} ${picker} ${affixed ? 'affixed' : ''}`}>
        <div className="left">
          {department?.scheduleParams?.global_stats && (
            <div className="global">
              <span className={globalTotalHoursIndicator}>
                {minutesToHoursAndOrMinutes(
                  Number(Object.values(totalHours).reduce((result: any, value: any) => result + value, 0)) * 60,
                )}
                {hasGlobalTotalTargetHoursInMinute && (
                  <React.Fragment>/{minutesToHoursAndOrMinutes(globalTotalTargetHoursInMinute) || 0}</React.Fragment>
                )}
              </span>
              {(!!globalTotalBudget || !!globalTotalTargetBudget) &&
                Number(Object.values(shiftsCost).reduce((result: any, value: any) => result + value)) > 0 && (
                  <span>
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: currency || 'EUR' })
                      .format(Number(days.reduce((value, b) => value + (b.budget || 0), 0)))
                      .replace(/[,\.]/g, (match) => (match === ',' ? '.' : ','))
                      .replace(/(?:\.(?:00)+|,(?:00)+)$/, '')}
                    {/* {days.some((d) => d.target.budget) && <React.Fragment>/{globalTotalTargetBudget}</React.Fragment>} */}
                    {getSymbolFromCurrency(currency)}
                  </span>
                )}
              {globalTotalHoursInMinute !== 0 ? (
                <Popover
                  content={() => <TotalHoursDetails totalByRole={globalDetailsOfTotalByRole} currency={currency} />}
                  getPopupContainer={() => {
                    return document.getElementsByClassName('app-hours-container')[0] as HTMLElement;
                  }}
                >
                  <i className="icon-info-circled" style={{ cursor: 'pointer' }}></i>
                </Popover>
              ) : null}
            </div>
          )}
          {department?.scheduleParams?.global_stats &&
            (!!activeSection || showOtherDepartments || !showAbsences || !showShifts || !!filterOptions?.length) && (
              <div className="local">
                <span
                  style={{
                    backgroundColor: section ? section.background : 'initial',
                    borderColor: section ? section.background : 'initial',
                    color: section ? getContrast(section.background!) : 'initial',
                  }}
                >
                  {minutesToHoursAndOrMinutes(totalOfTotalMinutes) || '0h'}
                  {Number(totalOfTotalPrices) > 0 && (
                    <>
                      {' '}
                      -{' '}
                      {new Intl.NumberFormat('en-US', { style: 'currency', currency: currency || 'EUR' })
                        .format(Number(totalOfTotalPrices))
                        .slice(Number(totalOfTotalPrices) < 0 ? 2 : 1)
                        .replace(/[,\.]/g, (match) => (match === ',' ? '.' : ','))
                        .replace(/(?:\.(?:00)+|,(?:00)+)$/, '')}
                    </>
                  )}
                  {getSymbolFromCurrency(currency)}
                </span>
                <Popover
                  content={() => <TotalHoursDetails totalByRole={detailsOfTotalByRole} currency={currency} />}
                  getPopupContainer={() => {
                    return document.getElementsByClassName('app-hours-container')[0] as HTMLElement;
                  }}
                >
                  <i className="icon-info-circled" style={{ cursor: 'pointer' }}></i>
                </Popover>
              </div>
            )}
        </div>
        <div className={`right`}>
          {[...new Array(totalByRole.length)].map((_x, index) => {
            const totalMinutes = totalByRole[index].reduce((a, b) => a + b.minutes, 0);
            const totalPrices = totalByRole[index].reduce((a, b) => a + b.price, 0);
            const globalHoursInMinute = days[index]?.hoursInMinute || 0;
            const globalTargetHoursInMinute = days[index]?.target?.hoursInMinute;
            let globalHoursIndicator = '';
            if (globalTargetHoursInMinute) {
              if (globalHoursInMinute > globalTargetHoursInMinute) {
                globalHoursIndicator = 'red';
              } else if (globalHoursInMinute < globalTargetHoursInMinute) {
                globalHoursIndicator = 'blue';
              }
            }

            const globalTotalsByUserCategory = days[index]?.totalsByUserCategory;
            const customTemplate = quotaCustomTemplates.find(
              (template) => template.week_number == startDate.week() && template.year == startDate.year(),
            );
            const activeQuotaTemplate = quotaTemplates.find((template) =>
              customTemplate ? customTemplate.template_id == template.id : template.default,
            );
            const quotaTemplateDetails = activeQuotaTemplate
              ? activeSection
                ? activeQuotaTemplate.details.filter((detail) => String(detail.section_id) == activeSection)
                : activeQuotaTemplate.details
              : [];
            const globalQuotaTemplateDetails = activeQuotaTemplate ? activeQuotaTemplate.details : [];
            const quotaForDay = quotaTemplateDetails
              .filter((detail) => detail.day == index + 1)
              .reduce((accumulator, detail) => {
                if (detail.value > accumulator) {
                  return detail.value;
                }
                return accumulator;
              }, 0);
            const globalQuotaForDay = globalQuotaTemplateDetails
              .filter((detail) => detail.day == index + 1)
              .reduce((accumulator, detail) => {
                if (detail.value > accumulator) {
                  return detail.value;
                }
                return accumulator;
              }, 0);
            const productivityForDay = turnover
              ? productivityCalculationType == 'HOURS_WORKED' &&
                turnover[days[index].date] &&
                totalHours[days[index].date_string]
                ? turnover[days[index].date].value / (totalHours[days[index].date_string] + productivityDefaultHours)
                : turnover[days[index].date] && shiftsCost[days[index].date_string]
                ? turnover[days[index].date].value / (shiftsCost[days[index].date_string] + productivityDefaultHours)
                : 0
              : 0;
            const provisional =
              turnover &&
              turnover[days[index].date] &&
              turnover[days[index].date].provisional &&
              turnover[days[index].date].provisional > 0
                ? Number(
                    (turnover[days[index].date].provisional /
                      (productivityCalculationType === 'HOURS_WORKED'
                        ? totalHours[days[index].date_string] + productivityDefaultHours
                        : shiftsCost[days[index].date_string] + productivityDefaultHours)) *
                      productivityCoefficient,
                  ).toFixed(2)
                : turnover &&
                  turnover[days[index].date] &&
                  turnover[days[index].date].type === 'provisional' &&
                  turnover[days[index].date].value
                ? Number(
                    (turnover[days[index].date].value /
                      (productivityCalculationType === 'HOURS_WORKED'
                        ? totalHours[days[index].date_string] + productivityDefaultHours
                        : shiftsCost[days[index].date_string] + productivityDefaultHours)) *
                      productivityCoefficient,
                  ).toFixed(2)
                : '-';
            const real =
              turnover && turnover[days[index].date]
                ? turnover[days[index].date].type === 'real'
                  ? Number(
                      (turnover[days[index].date].value /
                        (productivityCalculationType === 'HOURS_WORKED'
                          ? totalHours[days[index].date_string] + productivityDefaultHours
                          : shiftsCost[days[index].date_string] + productivityDefaultHours)) *
                        productivityCoefficient,
                    ).toFixed(2)
                  : '-'
                : '-';

            return (
              <div key={`total_${index}`}>
                {(!!globalHoursInMinute ||
                  !!globalTargetHoursInMinute ||
                  !!days[index]?.budget ||
                  !!days[index]?.target?.budget) &&
                  department?.scheduleParams?.global_stats && (
                    <div className="global">
                      {(!showProductivityIndex || productivityForDay == 0) && (
                        <span className={globalHoursIndicator}>
                          {totalHours[days[index].date_string]
                            ? minutesToHoursAndOrMinutes(totalHours[days[index].date_string] * 60)
                            : 0}
                          {!!globalTargetHoursInMinute && (
                            <React.Fragment>
                              /{minutesToHoursAndOrMinutes(globalTargetHoursInMinute) || 0}
                            </React.Fragment>
                          )}
                        </span>
                      )}
                      {(!showProductivityIndex || productivityForDay == 0) &&
                        Number(shiftsCost[days[index].date_string]) > 0 && (
                          <Popover
                            content={() => (
                              <TotalHoursDetails totalByRole={globalTotalsByUserCategory || []} currency={currency} />
                            )}
                            getPopupContainer={() => {
                              return document.getElementsByClassName('app-hours-container')[0] as HTMLElement;
                            }}
                          >
                            <span style={{ cursor: 'pointer' }}>
                              {new Intl.NumberFormat('en-US', { style: 'currency', currency: currency || 'EUR' })
                                .format(Number(days[index].budget))
                                .replace(/[,\.]/g, (match) => (match === ',' ? '.' : ','))
                                .replace(/(?:\.(?:00)+|,(?:00)+)$/, '')}
                              {getSymbolFromCurrency(`${currency}`)}
                            </span>
                          </Popover>
                        )}
                      {!showProductivityIndex && comparison && (
                        <React.Fragment>
                          {!days[index]?.differences?.missing?.length && !days[index]?.differences?.added?.length && (
                            <i className="comparison-indicator" style={{ backgroundColor: colors.green }}></i>
                          )}
                          {(!!days[index]?.differences?.missing?.length ||
                            !!days[index]?.differences?.added?.length) && (
                            <Popover
                              content={() => <ComparisonDetails differences={days[index]?.differences} />}
                              getPopupContainer={() => {
                                return document.getElementsByClassName('app-hours-container')[0] as HTMLElement;
                              }}
                            >
                              <i
                                className="comparison-indicator"
                                style={{ cursor: 'pointer', backgroundColor: colors.red }}
                              ></i>
                            </Popover>
                          )}
                        </React.Fragment>
                      )}
                      {showProductivityIndex && productivityForDay > 0 && (
                        <>
                          {department?.scheduleParams?.productivity_clocking ? (
                            <Popover
                              content={() => (
                                <ProductivityIndexRealDetailsStyled provisional={provisional} real={real} />
                              )}
                              getPopupContainer={() => {
                                return document.getElementsByClassName('app-hours-container')[0] as HTMLElement;
                              }}
                            >
                              {Number(productivityForDay * productivityCoefficient) !== Infinity && (
                                <div className="productivity_index_real_container">
                                  {Number(productivityForDay * productivityCoefficient).toFixed(2)}
                                </div>
                              )}
                            </Popover>
                          ) : (
                            <>
                              {Number(productivityForDay * productivityCoefficient) && (
                                <span className="productivity-index" style={{ cursor: 'pointer' }}>
                                  {Number(productivityForDay * productivityCoefficient).toFixed(2)}
                                </span>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {!!showHeadCount && (
                        <div
                          // className={
                          //   customTemplate &&
                          //   quotaTemplateDetails.length > 0 &&
                          //   quotaForDay &&
                          //   !isNullOrUndefined(days[index].headCount)
                          //     ? 'custom-quota'
                          //     : ''
                          // }
                          key={`totalEmployee_${index}`}
                          style={{ marginLeft: 5 }}
                        >
                          {!isNullOrUndefined(days[index].headCount) && (
                            <React.Fragment>
                              <i className="icon-user"></i> {days[index].headCount}
                              {globalQuotaTemplateDetails.length > 0 && globalQuotaForDay > 0 && (
                                <>/{globalQuotaForDay}</>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                {(!!activeSection || showOtherDepartments || !showAbsences || !showShifts || !!filterOptions?.length) &&
                  department?.scheduleParams?.filter_stats && (
                    <div className="local">
                      <span
                        style={{
                          backgroundColor: section ? section.background : 'initial',
                          borderColor: section ? section.background : 'initial',
                          color: section ? getContrast(section.background!) : 'initial',
                        }}
                      >
                        {minutesToHoursAndOrMinutes(totalMinutes) || '0h'}
                        {totalPrices > 0 && (
                          <>
                            {' '}
                            -{' '}
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: currency || 'EUR' })
                              .format(totalPrices)
                              .slice(Number(totalPrices) < 0 ? 2 : 1)
                              .replace(/[,\.]/g, (match) => (match === ',' ? '.' : ','))
                              .replace(/(?:\.(?:00)+|,(?:00)+)$/, '')}
                            €{' '}
                          </>
                        )}
                      </span>
                      <Popover
                        content={() => <TotalHoursDetails totalByRole={totalByRole[index]} currency={currency} />}
                        getPopupContainer={() => {
                          return document.getElementsByClassName('app-hours-container')[0] as HTMLElement;
                        }}
                      >
                        <i className="icon-info-circled" style={{ cursor: 'pointer' }}></i>
                      </Popover>
                      {!!showHeadCount && (
                        <div
                          // className={
                          //   customTemplate &&
                          //   quotaTemplateDetails.length > 0 &&
                          //   quotaForDay &&
                          //   !!selectedHeadcounts[index]
                          //     ? 'custom-quota'
                          //     : ''
                          // }
                          key={`totalEmployee_active_${index}`}
                        >
                          {!!selectedHeadcounts[index] && (
                            <React.Fragment>
                              <i className="icon-user"></i> {selectedHeadcounts[index]}
                              {quotaTemplateDetails.length > 0 && quotaForDay && <>/{quotaForDay}</>}
                            </React.Fragment>
                          )}
                        </div>
                      )}
                    </div>
                  )}
              </div>
            );
          })}
          {!month_view && !!department?.scheduleParams?.sidebarCounters && (
            <div className="counter-column">{t('GLOBAL.COUNTERS')}</div>
          )}
        </div>
      </div>
    </Affix>
  );
};

const TotalHoursStyled = styled(TotalHours)`
  z-index: 3;
  display: flex;
  position: relative;
  border-bottom: 1px solid ${Colors.blueLight};
  background-color: #ffcf97;

  &.month {
    width: fit-content;
    > div.right > div {
      width: 180px;
    }
    &.affixed {
      max-width: 100%;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 900px) {
    > div {
      &.left {
        width: 150px !important;
      }
    }
  }

  > div {
    &.left {
      flex-shrink: 0;
      position: sticky;
      left: 0;
      border-right: 1px solid ${Colors.blueLight};
      width: 210px;
      background-color: #ffcf97;
      padding: 10px;
      @media print {
        width: 120px;
      }

      .global {
        font-weight: bold;
      }
    }

    &.right {
      display: flex;
      flex-grow: 1;

      > div {
        padding: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 0px;
        width: 50px;
        display: flex;
        flex-direction: column;

        &:nth-child(n + 2) {
          border-left: 1px solid ${Colors.blueLight};
        }
      }
    }

    &.right,
    &.left {
      .counter-column {
        background-color: ${colors.blueLight};
        font-weight: bold;
        border-bottom: 1px solid ${Colors.blueLight};
      }

      .global {
        font-size: 10px;
        display: flex;
        align-items: center;
        span {
          background: rgba(0, 0, 0, 0.14);
          border-radius: 3px;
          padding: 2px 3px;
          color: white;
          &:nth-child(n + 2) {
            margin-left: 5px;
          }
          &.blue:not(.indicator) {
            background-color: ${colors.bluePastel};
          }
          &.blue.indicator {
            color: ${colors.bluePastel};
          }
          &.red:not(.indicator) {
            background-color: ${colors.red};
          }
          &.red.indicator {
            color: ${colors.red};
          }
          &.productivity-index {
            background-color: white;
            color: ${colors.red};
          }
        }
        .comparison-indicator {
          margin-left: 3px;
          width: 12px;
          height: 12px;
          background-color: ${colors.green};
          display: inline-block;
          border-radius: 6px;
          border: 1px solid white;
        }

        .productivity_index_real_container {
          background-color: #fff;
          padding: 2px 3px;
          border-radius: 3px;
          color: #fff;
          background-color: blueviolet;
        }
      }

      .local {
        font-size: 10px;
        color: ${colors.grey};
        margin: 3px 0 0;
        display: flex;
        align-items: center;

        span {
          border: 1px solid ${colors.grey};
          border-radius: 3px;
          padding: 0px 6px;
          &:nth-child(n + 2) {
            margin-left: 5px;
          }
        }
      }
    }

    .custom-quota {
      background-color: ${colors.orangeBright};
      padding: 2px 7px;
      border-radius: 8px;
      margin-left: 0;
    }
  }
`;

export default TotalHoursStyled;
